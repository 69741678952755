import React from "react";
import Home from "../../Component/MainComponent/Home";
import About from "../../Component/MainComponent/About";
// import Skill from "../../Component/MainComponent/Skill";
import Project from "../../Component/MainComponent/Project";
import Contact from "../../Component/MainComponent/Contact";
import Footer from "../../Component/MainComponent/Footer";
import CodeOnline from "../../Component/CodeOnline";

function Main() {

  return (
    <div className="App">
      <Home />
      <About />
      <Project />
      {/* <Skill /> */}
      <CodeOnline />
      <Contact />
      <Footer />
    </div>
  );
}

export default Main;

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import IconContactTs from "../../../Assets/img/ilustrasion-contact.png"
import "./style.scss";


const ServiceEmailId = process.env.REACT_APP_API_URL_SERVICE_ID_EMAIL;
const TemplateId = process.env.REACT_APP_API_URL_TEMPLATE_ID_EMAIL;
const UserEmailId = process.env.REACT_APP_API_URL_USER_ID_EMAIL;


const Contatcs = () => {
  const form = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(form.current)
    emailjs
      .sendForm(ServiceEmailId, TemplateId, form.current, UserEmailId)
      .then(
        (result) => {
          toast.success(`message successfully sent 😉`);
          form.current.reset();
          console.log(result.text);
          setIsLoading(false);
        },
        (error) => {
          toast.error(`message failed to send 😨`);
          console.log(error.text);
          setIsLoading(false);
        }
      );
  };

  return (
    <>
      <div className="consulting" id="Contact">
        <div className="container">
          <div className="row">
            <div data-aos="fade-right"  className="col-lg-6">
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="consulting-form-title mb-1">Name</div>
                    <input
                      name="user_name"
                      className="custom-input form-control"
                      type="text"
                      required
                      placeholder="Input full name"
                    />
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="consulting-form-title mb-1">Email</div>
                    <input
                      name="user_email"
                      className="custom-input form-control"
                      type="email"
                      required
                      placeholder="Type your email"
                    />
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="consulting-form-title mb-1">Subject</div>
                    <input
                      name="user_subject"
                      className="custom-input form-control"
                      type="text"
                      required
                      placeholder="Input full name"
                    />
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="consulting-form-title mb-1">Massage</div>
                    <textarea
                      name="user_message"
                      required
                      className="custom-input-area form-control"
                      placeholder="Describe yourself here..."
                    ></textarea>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <button type="submit" className="button-custom w-100 ml-0">
                      {isLoading ? <CircularProgress /> : "Les't talk"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6">
              <div className="title-consulting">Contack <span>Me.</span></div>
              <div className="icon-consulting">
                <a href="https://github.com/hanjarraes" target="_blank" rel="noreferrer"><i className="ri-github-fill" /></a>
                <a href="https://www.linkedin.com/in/muhammad-hanjarraes-61040828b/" target="_blank" rel="noreferrer"><i className="ri-linkedin-box-fill" /></a>
                <a href="https://www.instagram.com/raesshjr/" target="_blank" rel="noreferrer"><i className="ri-instagram-fill" /></a>
              </div>
              <div>
                <div data-aos="zoom-in" className="bg-character" />
                <img data-aos="fade-left"  data-aos-duration="1500" src={IconContactTs} alt="icon-contact" className="character-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contatcs;

const dataProject = [
    {
        img: "https://i.imgur.com/rWiRj47.png",
        title: "Invoice Generator",
        tech: ['Reactjs', 'Bootstrap 5', 'HTML', 'Javascript', 'AOS'],
        link: "https://invoice.raess.fun/",
        sourceCode: "https://github.com/hanjarraes/generator-invoice",
        about: `This is an invoice generator project developed using React. With this app, you can easily add items with specific details, configure quantities, prices, tax rates and discounts. Additionally, you can download invoices in PDF format directly to your device. I utilize jspdf-react to take data from the modal and convert it into a PDF file, so it can be saved without having to go through canvas. I created this project solely to utilize my free time.`,
    },
    {
        img: "https://i.imgur.com/7psoGwG.png",
        title: "Slider HKTI Tamara Bumi Indonesia Kepulauan Riau",
        tech: ['Reactjs', 'Bootstrap 5', 'HTML', 'Javascript', 'AOS', 'SASS', 'RemixIcon'],
        link: "https://hktitamara-bi.com/",
        about: `The HKTI TAMARA Cooperative (Indonesian Farmers Harmony Association Cooperative Tani Makmur Sejahtera) is part of or one of the HKTI LSOs (Indonesian Farmers Harmony Association Organizational Wing Institution). The General Chair of the Indonesian Farmers' Harmony Association (HKTI) is Jendral TNI (Purn) Dr. H. Moeldoko, S.I.P., M.A.`,
    },
    {
        img: "https://i.imgur.com/aEiasRe.png",
        title: "NextUp",
        tech: ['Reactjs', 'Bootstrap', 'HTML', 'SASS', 'Javascript', 'AOS'],
        link: "https://nextup.fun/",
        about: "nextup agency is a creative design and development agency based in Batam, Indonesia. they are here to be part of your business development strategy through design and software development solutions that suit your business values and identity, so as to create digital products that have a positive impact and encourage sustainable business growth.",
    },
    {
        img: "https://i.imgur.com/bZI88Uh.png",
        title: "PGN Pertamina",
        tech: ['Reactjs', 'Bootstrap', 'HTML', 'SASS', 'Javascript', 'AOS', 'Redux', 'typescript', 'chart.js'],
        link: "https://www.pgas.id/",
        about: "PGN ( Perusahaan Gas Negara ) Pertamina provides products and services tailored to customer needs ranging from Residential and Small Business Customers, Commercial and Industrial (Business) Customers and Transportation Customers. This company profile is intended as an initial means of registering and viewing the price list for State Gas installations.",
    },
    {
        img: "https://i.imgur.com/UPvfXrM.png",
        title: "Makanat",
        tech: ['Reactjs', 'Bootstrap', 'HTML', 'SASS', 'Javascript', 'AOS', 'Redux', 'hero-sleder'],
        // link:"https://makanat.com/listings?activity_id=null&category_id=1&distance=8.301677972693387&latitude=-0.023260115938242003&longitude=-0.03680124878883362",
        about: "",
    }
]

export default dataProject
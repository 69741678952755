import React from "react";
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import '../src/Assets/scss/style.scss'
import 'remixicon/fonts/remixicon.css'
import Main from './Pages/Main';
import AOS from "aos";
import "aos/dist/aos.css";

 const App = () =>{
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
       <Main />
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        icon
      />
    </>
  );
}

export default App;

import React from "react";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import "./style.scss";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const Modal = ({ open, handleClose, item }) => {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            className="custom-dialog"
        >
            <div className="content-modal">
                <div className="modal-close" onClick={handleClose}><i className="ri-arrow-left-line" /> <span>Go Back</span></div>
                <hr />
                <div className="modal-img">
                    <span> {item.title}</span>
                    <img src={item.img} alt="img-works" />
                </div>
                <div className="modal-about">
                    <span>About <i className="ri-question-line" /> </span>
                    <p>{item.about}</p>
                </div>
                <div className="modal-tech">
                    <span>Technologies <i className="ri-code-box-line" /> </span>
                    <div className='desc-tech'>
                        {item.tech.map(tech => (
                            <span key={tech}>{tech}</span>
                        ))}
                    </div>
                </div>
                {item.link &&
                    <div className="modal-web">
                        <span>Link <i className="ri-links-line" /> </span>
                        <a href={item.link} target="_blank" rel="noreferrer">
                            {item.link}
                        </a>
                    </div>
                }
                {item.sourceCode &&
                    <div className="modal-web">
                        <span>Source Code <i className="ri-code-box-line" /></span>
                        <a href={item.sourceCode} target="_blank" rel="noreferrer">
                            {item.sourceCode}
                        </a>
                    </div>
                }
            </div>
        </Dialog>
    );
}

export default Modal;

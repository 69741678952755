import React, { useState } from "react";
// import Typewriter from 'typewriter-effect';
import logo from "../../../Assets/img/icon-black.png"
import catImg from "../../../Assets/img/cat.png";

const Home = () => {
  // fixed Header
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    header.classList.toggle("active", window.scrollY > 0);
  });
  // Toogle Menu
  const [show, setShow] = useState(false);

  const openNavMobile = () => {
    setShow(!show);
  };
  return (
    <div className="home" id="Home">
      <div className="bgHome" />
      <div className="homeBg">
        <div className="header d-flex align-items-center px-4 py-2">
          <div className="logo-nav d-md-flex d-none">
            <img src={logo} alt="" />
          </div>
          <nav
            role="navigation"
            id="nav-main"
            className="navigation-main d-md-flex d-none justify-content-center justify-content-lg-start ml-3"
          >
            <a className="header-nav" href="#Home">Home</a>
            <a className="header-nav" href="#About">About</a>
            <a className="header-nav" href="#Skills">Skill</a>
            <a className="header-nav" href="#LiveEditor">Live Editor</a>
            <a className="header-nav" href="#Works">Works</a>
            <a className="header-nav" href="#Contact">Contact Us</a>
          </nav>
        </div>
        {/* HOME CONTENT */}
        <div className="container">
          <div data-aos="fade-right" data-aos-duration="1500" className="homeContent">
            <div className="home__meta">
              <img src={catImg} alt="catHero" className="cat-hero" />
              <h1 className="home-text pz-10">WELCOME TO MY WORLD</h1>
              <h2 className="home-text pz-10 heading">Hi, I’m Hanjarraes
                {/* <Typewriter
                  className="home-text pz-10"
                  onInit={(data) => {
                    data.typeString("Hi, I’m Hanjarraes")
                      // .callFunction(() => {})
                      .pauseFor(2000)
                      .deleteAll()
                      .typeString("WELCOME TO MY WORLD ")
                      .start();
                  }}
                /> */}
              </h2>
              <h3 className="home-text sweet pz-10">FrontEnd Developer.</h3>
              <h4 className="home-text pz-10 heading">Based in Batam.</h4>
            </div>
          </div>
        </div>
      </div>

      {/* Toogle Menu Mobile*/}
      <div className="mobile-menu">
        <div className={`sidebar d-md-none${show ? ' open' : ''}`}>
          <a href="#Home" className="sideNavbar" onClick={openNavMobile}>
            <i className="ri-home-3-line" />
          </a>
          <a href="#About" className="sideNavbar" onClick={openNavMobile}>
            <i className="ri-user-5-line" />
          </a>
          <a href="#Works" className="sideNavbar" onClick={openNavMobile}>
            <i className="ri-building-2-line" />
          </a>
          <a href="#Skills" className="sideNavbar" onClick={openNavMobile}>
            <i className="ri-code-s-slash-line" />
          </a>
          <a href="#LiveEditor" className="sideNavbar" onClick={openNavMobile}>
            <i className="ri-terminal-window-fill" />
          </a>
          <a href="#Contact" className="sideNavbar" onClick={openNavMobile}>
            <i className="ri-contacts-book-line" />
          </a>
        </div>
        <div className={`logo-nav-mobile d-flex d-md-none ${show ? ' open' : ''}`}>
          <img src={logo} alt="" onClick={openNavMobile} />
        </div>
      </div>
    </div>
  );
}

export default Home;

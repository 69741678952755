import React, { useState } from 'react';
import Modal from '../../Modal';
import dataProject from './dataProjects';
import "./style.scss";


const Project = () => {
    document.addEventListener("DOMContentLoaded", function () {
        window.addEventListener("scroll", function () {
            const upToTop = document.querySelector("a.bottomToTop");
            if (upToTop) {
                upToTop.classList.toggle("active", window.scrollY > 0);
            }
        });
    });
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState();

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="project componentSpace" id="Works">
            <div className="heading">
                <h1 className="heading">My Latest Works</h1>
            </div>
            <div className="container">
                <div className="row">
                    {dataProject.map((data, idx) => {
                        return (
                            <div key={data.title + idx} className="col-12 col-md-4">
                                <div className="card" onClick={() => {
                                    setItem(data)
                                    setOpen(true);
                                }}>
                                    <img src={data.img} alt={data.title + idx} className="card-img" />
                                    <div className="card-text">
                                        <div className='title-card'>{data.title}</div>
                                        <div className='desc-card'>
                                            {data.tech.map(tech => (
                                                <span key={tech}>{tech}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className="d-flex align-items-center justify-content-center pt-3">
                    <button className="btn button-custom">View more</button>
                </div> */}
            </div>
            {item &&
                <Modal
                    open={open}
                    handleClose={handleClose}
                    item={item}
                />
            }

        </div>
    );
}

export default Project;

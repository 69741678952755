import React from "react";
import aboutImg from "../../../Assets/img/about.png";
// import catAbout from "../../../Assets/img/cat-gif.gif";

const About = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className="about aboutContent" id="About">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6" data-aos="fade-up-right" data-aos-duration="1500" >
            <img src={aboutImg} alt="" className="aboutImg" />
          </div>
          <div className="col-12 col-md-6" data-aos="fade-up-left" data-aos-duration="1500" >
            <h1 className="aboutHeading">About Me</h1>
            <div className="aboutMeta">
              <p className="pColor">
                Regards! I'm Hanjar. My passion lies in creating solutions with JavaScript, a language that continues to captivate me with its versatility and power.
              </p>
              <p className="pColor">
                On the front-end, I use the dynamic capabilities of ReactJS, while on the back-end, I rely on the strong foundation provided by ExpressJS. This perfect union empowers me to create immersive and efficient web applications.
              </p>
              <p className="pColor">
                What really invigorates me is pursuing new challenges. They served as a gateway to a world of discovery, where I uncovered the intricacies and learned invaluable lessons. In every project, I not only find solutions, but also a wealth of knowledge that fuels my growth as a developer.
              </p>
              <p className="pColor">
                You can download my CV if you want to know more about me
              </p>
              <div className="aboutButton">
                <button className="btn button-custom" onClick={() => openInNewTab('https://github.com/hanjarraes')}>
                  Find me on Github 
                  <i className="ri-github-fill" />
                  </button>
                <button className="btn button-custom" onClick={() => openInNewTab('https://www.linkedin.com/in/muhammad-hanjarraes-61040828b/')}>
                  Find me on LinkeIn 
                  <i className="ri-linkedin-box-fill" />
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* <div>
          <img src={catAbout} alt="" className="" />
        </div> */}
    </div>
  );
}

export default About;

import React, { useState, useEffect, useRef } from "react";
import AceEditor from "react-ace";
import { DefaultHTML, DefaultCSS, DefaultJS } from "./DataDefult";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-github_dark";
import "./style.scss";

const CodeOnline = () => {
  const [htmlCode, setHtmlCode] = useState(DefaultHTML);
  const [cssCode, setCssCode] = useState(DefaultCSS);
  const [jsCode, setJsCode] = useState(DefaultJS);
  const outputRef = useRef(null);

  const thameCode = "github_dark"

  useEffect(() => {
    const output = outputRef.current.contentDocument;
    output.body.innerHTML = htmlCode;
    const style = output.createElement("style");
    style.textContent = cssCode;
    output.head.appendChild(style);
    const script = output.createElement("script");
    script.textContent = jsCode;
    output.body.appendChild(script);
  }, [htmlCode, cssCode, jsCode]);

  return (
    <div className="codeOnline" id="LiveEditor">
      <div className="container">
        <div className="heading">
          <h1 className="heading">Live Editor</h1>
        </div>
        <div className="row pt-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-4 d-flex flex-column w-100" data-aos="fade-up"  data-aos-duration="1500" >
                <label className="label-code">
                  <i className="ri-html5-line" />HTML
                </label>
                <AceEditor
                  mode="html"
                  theme={thameCode}
                  name="html-editor"
                  defaultValue={htmlCode}
                  style={{ width: "100%", height: "200px" }}
                  setOptions={{ useWorker: false }}
                  onChange={(e) => setHtmlCode(e)}
                />
              </div>
              <div className="col-12 col-md-4 d-flex flex-column w-100" data-aos="fade-up"  data-aos-duration="1500" >
                <label className="label-code">
                  <i className="ri-css3-fill" />CSS
                </label>
                <AceEditor
                  mode="css"
                  theme={thameCode}
                  name="css-editor"
                  defaultValue={cssCode}
                  style={{ width: "100%", height: "200px" }}
                  setOptions={{ useWorker: false }}
                  onChange={(e) => setCssCode(e)}
                />
              </div>
              <div className="col-12 col-md-4 d-flex flex-column w-100" data-aos="fade-up"  data-aos-duration="1500" >
                <label className="label-code">
                  <i className="ri-javascript-fill" />JavaScript
                </label>
                <AceEditor
                  mode="javascript"
                  theme={thameCode}
                  name="css-editor"
                  defaultValue={jsCode}
                  style={{ width: "100%", height: "200px" }}
                  setOptions={{ useWorker: false }}
                  onChange={(e) => setJsCode(e)}
                />
              </div>
            </div>

          </div>
          <div className="col-12" data-aos="fade-up"  data-aos-duration="1500">
            <label className="label-code">
              <i className="ri-play-fill" />Output
            </label>
            <iframe
              ref={outputRef}
              className="frame-output"
              title="Output Frame"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeOnline;

import React from "react";
import Logo from "../../../Assets/img/icon-black.png"
import "./style.scss";

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="content-footer">
          <img src={Logo} alt="logoUis" className="logo-footer pe-5" />
          <div className="address-footer">
            Tiban Pesona Rhabayu, Patam Lestari - Kecamatan Sekupang, Kota
            Batam, Indonesia
          </div>
          <div>+62 852 6313 2152</div>
          <div>hanjarraess@gmail.com</div>
        </div>
        <hr />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <nav
              role="navigation"
              id="nav-main"
              className="navigation-main d-flex justify-content-center justify-content-lg-start"
            >
              <a className="footer-nav" href="#Home">Home</a>
              <a className="footer-nav" href="#About">About</a>
              <a className="footer-nav" href="#Skills">Skill</a>
              <a className="footer-nav" href="#LiveEditor">Live Editor</a>
              <a className="footer-nav" href="#Works">Works</a>
              <a className="footer-nav" href="#Contact">Contact Us</a>
            </nav>
          </div>

          <div className="col-12 col-lg-4  text-lg-end text-center">
            lest go build love in Indonesia
            <i className="ri-heart-2-fill" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
